<template>
    <div class="bg-black bg-opacity-90 rounded-md">
        <div v-on-clickaway="focusOut" class="w-full relative text-sm" :class="{ 'pointer-events-none opacity-50' : disabled }">
            <div :class="classes" class="block relative w-full text-left cursor-default rounded-md" @click.prevent="toggleDropdownList()">
                <v-form-input v-model="searchFilter" type="text" :placeholder="getLabel(value) ? getLabel(value) : defaultLabel ? defaultLabel : ' '" :disabled="disabled" />
                <!-- <input v-model="searchFilter" :placeholder="getLabel(value) ? getLabel(value) : defaultLabel ? defaultLabel : ' '" class="truncate pl-8 py-3 pr-14 text-gray-200 ring-0 focus:outline-none block w-full bg-black bg-opacity-50 rounded-sm border border-gray-50 border-opacity-10"> -->
                <span v-if="searchFilter.length > 0" class="absolute inset-y-0 right-0 flex items-center pr-8" @click.prevent="searchFilter = ''">
                    <svg class="cursor-pointer h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                </span>
                <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <svg class="h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                </span>
            </div>
            <ul v-show="dropDownList" class="absolute z-10 mt-1 w-full bg-slate bg-opacity-90 text-gray-300 shadow-lg max-h-60 text-sm ring-none ring-black ring-opacity-5 overflow-auto focus:outline-none" x-max="1" tabindex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="activeDescendant">
                <li v-for="({ label, value: optionValue }, index) in filteredOptions" :id="'option_' + index" :key="'option_' + index" :value="optionValue" class="cursor-default select-none relative py-2 pl-8 pr-12 hover:bg-primary-700 hover:text-white" :class="input == optionValue ? 'text-white bg-primary-700' : ''" role="option" @click="selectOption(optionValue)">
                    <slot name="option" :option="{ label, value: optionValue }">
                        <span class="font-normal block truncate text-sm">
                            {{ label }}
                        </span>
                        <span v-show="input == optionValue" class="absolute inset-y-0 right-0 flex items-center pr-4">
                            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                            </svg>
                        </span>
                    </slot>
                </li>
            </ul>
        </div>

        <template v-for="(errorMessage, index) in errors[name]">
            <v-text :key="'error_message_' + index" type="error">
                {{ errorMessage }}
            </v-text>
        </template>
    </div>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";
import FormMixin from "./form-mixin";

export default {
    mixins: [
        FormMixin,
        clickaway,
    ],
    props: {
        options: {
            type: Array,
            required: false,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            required: false,
            default: () => false,
        },
        defaultLabel: {
            type: String,
            required: false,
            default: () => "",
        },
    },
    data() {
        return {
            /**
             * The base classes for the component.
             *
             * @var String
             */
            baseClasses: "block ring-black rounded-md focus:ring-2 focus:ring-inset focus:ring-primary-700 w-full border border-default disabled:opacity-75 disabled:cursor-not-allowed",

            /**
             * The available component states.
             *
             * @var Object
             */
            states: {
                /**
                 * The classes for when the component is in a default state.
                 */
                default: "text-gray-200",

                /**
                 * The classes for when the component is readonly.
                 */
                readonly: "bg-transparent",

                /**
                 * The classes for when the component has errors.
                 */
                error: "bg-gray-100 border-red-500 focus:border-red-500 ring-red-500 focus:ring-red-500",
            },
            dropDownList: false,
            selected: null,
            searchFilter: "",
        };
    },
    computed: {
        classes() {
            return `form-select ${this.baseClasses} ${this.states[this.state]} ${this.dropDownList ? "ring-none" : "ring-none"}`;
        },
        filteredOptions() {
            const filter = (row, query) => {
                let include = false;

                const value = (row.label || "").toString().toLowerCase();

                if (value && value.includes((query || "").toLowerCase())) {
                    include = true;
                }

                return include;
            };

            return this.options.filter((item) => filter(item, this.searchFilter));
        },
    },
    watch: {
        input: {
            handler(value) {
                this.$emit("input", value);
            },
            immediate: true,
            deep: true,
        },
    },
    methods: {
        toggleDropdownList() {
            this.dropDownList = !this.dropDownList;
        },
        selectOption(value) {
            this.input = value;
            this.searchFilter = "";
            this.toggleDropdownList();
        },
        focusOut() {
            this.dropDownList = false;
        },
        getLabel(value) {
            if (this.options) {
                const selected = this.options.find((option) => {
                    if (option.value !== null && value !== null) {
                        return option.value.toString() === value.toString();
                    }
                    return option.value === value;
                });
                if (selected) {
                    return selected.label;
                }
                return this.defaultLabel;
            }
            return null;
        },
    },
};
</script>
